<template>
<div class="page-clientes">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Administrar Clientes" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <exportar-excel v-show="1==2" :data="items_completos" :exportFields="encabezadoLayout" name="Clientes.xlsx">
                <v-btn id="botonExportar" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>&nbsp;
              <v-btn fab dark x-small color="green darken-2" title="Exportar Clientes" @click="get_clientes()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>&nbsp;
              <upload-excel-component title="Importar Clientes" :on-success="handleSuccess" :before-upload="beforeUpload" />
                &nbsp;
              <exportar-excel :data="camposLayoutClientes" :exportFields="encabezadoLayoutClientes" name="Plantilla_Clientes.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Clientes" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
              &nbsp;
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="agregar" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="fecha_alta" slot-scope="props">
                  {{get_fecha(props.row.fecha_alta)}}
                </template>
                <template slot="pass" slot-scope="props">
                  {{permiso(props.row.pass)}}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_cliente(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" v-if="!(props.row.nombre=='PUBLICO EN GENERAL' && props.row.rfc=='XAXX010101000')" @click.native="eliminar(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <!--v-btn x-small fab dark color="green" v-if="!(props.row.nombre=='PUBLICO EN GENERAL' && props.row.rfc=='XAXX010101000')" @click.native="pedir(props.row)" title="Pedidos en Linea" v-tippy>
                      <v-icon>mdi-account-lock</v-icon>
                    </v-btn-->
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="65%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Cliente</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs grow style="margin-bottom: 30px;" v-model="active_tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab :key="'datos_generales'">
            <v-card-title><b>Datos Generales</b></v-card-title>
          </v-tab>
          <v-tab :key="'datos_fiscales'" v-show="factura">
            <v-card-title><b>Información Fiscal</b></v-card-title>
          </v-tab>

          <v-tab-item :key="'datos_generales'" style="margin: 20px;">
            <fieldset>
              <legend><strong>Información General</strong></legend>
              <v-form ref="form_general" lazy-validation>
                <v-container grid-list-md @keyup.enter="update ? actualizar() : guardar()">
                  <v-row>
                    <v-col sm="3" md="3" lg="3">
                      <v-checkbox @change="clean_fiscales()" v-model="factura" label="¿El Cliente Factura?" :true-value="true" :false-value="false"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2" md="2" lg="2">
                      <v-text-field label="Alta" v-model="model.fecha_alta" disabled></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="7" lg="7">
                      <v-text-field v-if="(model.nombre=='PUBLICO EN GENERAL' && model.rfc=='XAXX010101000')" label="Nombre o Razón Social" v-model="model.nombre" :rules="[rules.required]" readonly disabled></v-text-field>
                      <v-text-field v-else label="Nombre o Razón Social" v-model="model.nombre" :rules="[rules.required]" @input="upperCaseInput('nombre')"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="5" lg="5">
                      <v-text-field label="Cargo" v-model="model.cargo"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field v-if="(model.nombre=='PUBLICO EN GENERAL' && model.rfc=='XAXX010101000')" label="RFC" v-model="model.rfc" :rules="[rules.required, rules.rfc]" @input="upperCaseInput('rfc')" readonly
                        disabled></v-text-field>
                      <v-text-field v-else label="RFC" v-model="model.rfc" :rules="[rules.required, rules.rfc]" @input="upperCaseInput('rfc')"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field label="CURP" v-model="model.curp" @input="upperCaseInput('curp')"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Teléfono Casa" v-model="model.telefono_casa"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Teléfono Oficina" v-model="model.telefono_oficina"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Fax" v-model="model.fax"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="4" lg="4">
                      <v-autocomplete v-model="model.tipo_persona" :items="tipos_persona" return-object :hide-selected="true" item-text="nombre" label="Tipo Persona" :rules="[requiredObject]">
                      </v-autocomplete>

                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-autocomplete v-model="model.sexo" :items="sexos" return-object :hide-selected="true" item-text="nombre" label="Sexo">
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Edad" v-model="model.edad" :rules="[rules.entero]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field label="Email" v-model="model.email" :rules="[rules.required, rules.email]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field label="Reg. IEPS" v-model="model.reg_iesps"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <legend><strong>Direcciones del Cliente</strong>
                    <v-btn fab dark x-small color="green" v-on:click="agregar_direcciones" title="Agregar Domicilio" v-tippy>
                      <v-icon dark>add</v-icon>
                    </v-btn>
                  </legend>

                </v-row>
                <v-container v-for="(row, index) in model.direcciones" v-bind:key="index">
                  <v-row>
                    <v-col sm="12" md="8" lg="8">
                      <v-text-field label="Domicilio" v-model="row.domicilio" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="Ext." v-model="row.numero_exterior" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="Int." v-model="row.numero_interior"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field label="Colonia" v-model="row.colonia" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6">
                      <v-text-field label="Ciudad" v-model="row.ciudad" :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="8" lg="8">
                      <v-text-field label="Referencias" v-model="row.referencias" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="CP" v-model="row.codigo_postal" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-btn fab dark x-small color="red" v-on:click="quitar_direccion(index)" title="Eliminar" v-tippy>
                        <v-icon dark>remove</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>

          <v-tab-item :key="'datos_fiscales'" style="margin: 20px;">
            <fieldset>
              <legend><strong>Información Fiscal</strong></legend>
              <v-form ref="form_fiscales" lazy-validation>
                <v-container grid-list-md>
                  <v-row>
                    <v-col sm="12" md="3" lg="3">
                      <v-text-field label="RFC" v-model="model.rfc" disabled></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-autocomplete v-model="model.datos_fiscales.regimen_fiscal" :items="regimenesFiscales" :hide-no-data="true" item-text="text" item-value="id" label="Régimen Fiscal" :rules="[rules.required]">
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="5" lg="5">
                      <v-text-field label="Razón Social" v-model="model.datos_fiscales.razon_social" :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="8" lg="8">
                      <v-text-field label="Domicilio" v-model="model.datos_fiscales.domicilio"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="Ext." v-model="model.datos_fiscales.numero_exterior"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="Int." v-model="model.datos_fiscales.numero_interior"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="5" lg="5">
                      <v-text-field label="Colonia" v-model="model.datos_fiscales.colonia"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="5" lg="5">
                      <v-text-field label="Ciudad" v-model="model.datos_fiscales.ciudad"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2">
                      <v-text-field label="CP" v-model="model.datos_fiscales.codigo_postal" :rules="[rules.required, rules.entero]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <br>
              </v-form>
              <v-form ref="form_morales" lazy-validation>
                <v-container v-show="(model.tipo_persona != '' && model.tipo_persona.id == 2)">
                  <legend><strong>Información para Persona Morales</strong></legend>
                  <v-row>
                    <v-col sm="12" md="5" lg="5">
                      <v-text-field label="Rep. Legal" v-model="model.datos_fiscales.representante"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="7" lg="7">
                      <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" v-model="model.datos_fiscales.fecha_sociedad" label="Fecha Sociedad" color="secondary" prepend-icon="event"
                            @blur="model.datos_fiscales.fecha_sociedad = parse_date(model.datos_fiscales.fecha_sociedad)" :rules="[valida_fecha]">
                          </v-text-field>
                        </template>

                        <v-date-picker v-model="model.datos_fiscales.fecha_sociedad" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                          <v-spacer />

                          <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Notario" v-model="model.datos_fiscales.notario"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="No. Escritura" v-model="model.datos_fiscales.no_escritura"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                      <v-text-field label="Ciudad" v-model="model.datos_fiscales.ciudad_representante"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? actualizar() : guardar()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.catalogos.clientes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
    this.model = this.clean_model();
    this.get_regimenes_fiscales();    
  },
  data() {
    return {
      clientes_all:[],
      //Clientes      
      encabezadoLayoutClientes: {
        "nombre":"nombre",
        "cargo":"cargo",
        "rfc":"rfc",
        "curp":"curp",
        "telefono_casa":"telefono_casa",
        "telefono_oficina":"telefono_oficina",
        "fax":"fax",
        "tipo_persona":"tipo_persona",
        "sexo":"sexo",        
        "edad":"edad",
        "email":"email",
        "reg_iesps":"reg_iesps",
        //domicilio
        "domicilio":"domicilio",
        "numero_exterior":"numero_exterior",
        "numero_interior":"numero_interior",
        "colonia":"colonia",     
        "ciudad":"ciudad",        
        "codigo_postal":"codigo_postal",
        "referencias":"referencias",
        //datos fiscales
        "razon_social":"razon_social",
        "regimen_fiscal":"regimen_fiscal",  
        "domicilio_fiscal":"domicilio_fiscal",
        "numero_exterior_fiscal":"numero_exterior_fiscal",
        "numero_interior_fiscal":"numero_interior_fiscal",
        "colonia_fiscal":"colonia_fiscal",
        "ciudad_fiscal":"ciudad_fiscal",
        "codigo_postal_fiscal":"codigo_postal_fiscal",              
      },      
      camposLayoutClientes: [{
        "nombre":"[Nombre del Cliente]",
        "cargo":"[Cargo del Cliente - Opcional]",
        "rfc":"[RFC del Cliente]",
        "curp":"[CURP del Cliente - Opcional]",
        "telefono_casa":"[Teléfono Casa - Opcional]",
        "telefono_oficina":"[Teléfono Oficina - Opcional]",
        "fax":"[Fax - Opcional]",
        "tipo_persona":"[Tipo de Persona: Persona Moral|Persona Fisica]",
        "sexo":"[Sexo: Masculino|Femenino] - Opcional",        
        "edad":"[Edad - Opcional]",
        "email":"[Email]",
        "reg_iesps":"[Registro IESPS - Opcional]",
        //domicilio
        "domicilio":"[Domicilio - Opcional]",
        "numero_exterior":"[No. Extrior - Opcional]",
        "numero_interior":"[No. Interior - Opcional]",
        "colonia":"[Colonia - Opcional]",
        "ciudad":"[Ciudad - Opcional]",
        "referencias":"[Referencias - Opcional]",
        "codigo_postal":"[C.P.  - Opcional]",        
        //datos fiscales
        "razon_social":"[Razón Social, solo si el cliente factura]",
        "regimen_fiscal":"[Clave SAT de regimen Fiscal 601|605|612|621|626]" , 
        "domicilio_fiscal":"[Domicilio Fiscal]",
        "numero_exterior_fiscal":"[No. Exterior]",
        "numero_interior_fiscal":"[No. Interior]",
        "colonia_fiscal":"[Colonia]",
        "ciudad_fiscal":"[Ciudad]",
        "codigo_postal_fiscal":"[Código Postal]", 
      }],
      camposObligatorios: [        
        "nombre",
        "rfc",
        "email",
        "tipo_persona"        
      ],

      encabezadoLayout: {
        RFC: "rfc",
        Nombre: "nombre",
        Cargo: "cargo",
        "Curp": "curp",
        "Tel Casa": "telefono_casa",
        "Tel Oficina": "telefono_oficina",
        Fax: "fax",
        "Tipo Persona": "tipo_persona.nombre",
        Sexo: "sexo",
        Edad: "edad",
        Email: "email",        
        "Fecha de Alta": "fecha_alta",
        Estatus: "estatus",
        "Razón Social": "datos_fiscales.razon_social",
        "Regimen Fiscal": "datos_fiscales.regimen_fiscal",
        "Domicilio": "datos_fiscales.domicilio",
        "No. Exterior": "datos_fiscales.numero_exterior",
        "No. Interior": "datos_fiscales.numero_interior",
        "Colonia": "datos_fiscales.colonia",
        "CP": "datos_fiscales.codigo_postal",
        "Ciudad": "datos_fiscales.ciudad_representante",
      },      
      regimenesFiscales: [],
      active_tab: "datos_generales",
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      factura: false,
      menu1: false,
      fab: [],
      tmp_fecha: "",
      tipos_persona: [{
        "id": 1,
        "nombre": "Persona Fisica"
      }, {
        "id": 2,
        "nombre": "Persona Moral"
      }],
      sexos: [{
        "id": 1,
        "nombre": "Masculino"
      }, {
        "id": 2,
        "nombre": "Femenino"
      }],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Clientes",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "rfc",
        "telefono_casa",
        "tipo_persona.nombre",
        "email",
        "fecha_alta",
        "estatus",
        //"pass",
        "actions"
      ],
      options: {
        filterable: ["nombre", "rfc", "telefono_casa", "tipo_persona.nombre", "email", "estatus"],
        sortable: ["nombre", "rfc", "telefono_casa", "tipo_persona.nombre", "email", "fecha_alta", "estatus"],

        headings: {
          nombre: "Nombre",
          rfc: "RFC",
          telefono_casa: "Telefono",
          "tipo_persona.nombre": "Tipo Persona",
          email: "Email",
          fecha_alta: "Fecha Alta",
          //pass: "Pedidos WEB",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns: {
            estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ]
        },
      },
      modal: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: [],
      items_completos:[], //Para exportar todos los clientes
    }
  },
  methods: {
    onStatusChanged(event) {
      this.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "clientes"
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    get_clientes: function() {
      window.dialogLoader.show('Espere un momento por favor..');
      let where = {
        "type": "clientes"
      };
      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            this.items_completos = response.data.docs;             
          }
        })
        .catch(error => {
          console.log(error)
        }).then(()=>{
            var btn = document.getElementById('botonExportar');
            if(btn){
              btn.click();
            }
            window.dialogLoader.hide();
        })
    },
    validaClienteImport: function(rfc, nombre) {
      var filter = this.clientes_all.find(e => e.rfc.toString().toLowerCase().trim() == rfc.toString().toLowerCase().trim() 
        && e.nombre.toString().toLowerCase().trim() == nombre.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
     beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isLt2M) {
        return true;
      }
      this.$swal({
        type: "warning",
        title: "¡Operación no Permitida!",
        text: "El archivo no debe ser mayor a 2MB.",
        footer: ""
      });
      return false;
    },
    validaRegimenFiscal: function(regimen) {
      var filter = this.regimenesFiscales.find(e => e.id.toString().toLowerCase().trim() == regimen.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
    getClientesAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "clientes"            
          }          
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.clientes_all = response.data.docs;
            } else
              self.clientes_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });

    },
    get_regimenes_fiscales: function() {
      let data = {
        "selector": {
          "type": "sat_regimenes_fiscales",
          "estatus": "Activo"
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.forEach((uso) => {
              this.regimenesFiscales.push({
                "id": uso.clave,
                "text": uso.clave + "-" + uso.nombre,
              });
            });
          } else
            this.regimenesFiscales = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los catalogos del SAT.",
            footer: ""
          });
        });
    },
    permiso: function(r) {

      if (r != undefined && r != "")
        return 'SI'
      else
        return 'NO'
    },
    pedir: function(row) {
      let self = this;
      this.$swal({
        type: "info",
        title: "Asignación de Permiso",
        text: "Permitir al cliente realizar pedidos en linea",
        footer: "El usuario y contraseña será el correo electrónico registrado.",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "SI",
        cancelButtonText: "NO"
      }).then(result => {
        console.log(result);
        if (result.isConfirmed) {

          let data = row;
          data.pass = data.email;
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + data._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El permiso se asigno correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');

            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al asignar el permiso..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        } else {
          let data = row;
          data.pass = "";
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + data._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El permiso se quito correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al quitar el permiso..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        }

      });
    },
    clean_model: function() {
      return {
        _id: "",
        _rev: "",
        type: "clientes",
        nombre: "",
        nombre_upper: "", //En mayusculas para busquedas en la app
        cargo: "",
        rfc: "XAXX010101000",
        curp: "",
        telefono_casa: "",
        telefono_oficina: "",
        fax: "",
        tipo_persona: "",
        sexo: "",
        edad: "",
        email: "",
        fecha_alta: window.moment(window.moment().toJSON()).format("DD-MM-YYYY"),
        reg_iesps: "",
        direcciones: [],
        datos_fiscales: {
          razon_social: "",
          regimen_fiscal: "",
          domicilio: "",
          numero_exterior: "",
          numero_interior: "",
          colonia: "",
          ciudad: "",
          codigo_postal: "",
          representante: "",
          fecha_sociedad: "",
          notario: "",
          no_escritura: "",
          ciudad_representante: ""
        },
        estatus: "Activo"
      };
    },
    clean_fiscales: function() {
      this.model.datos_fiscales = {
        razon_social: "",
        regimen_fiscal: "",
        domicilio: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        ciudad: "",
        codigo_postal: "",
        representante: "",
        fecha_sociedad: "",
        notario: "",
        no_escritura: "",
        ciudad_representante: ""
      }
    },
    get_cliente: function(id) {
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id)
        .then(response => {
          this.model = response.data;
          this.factura = this.model.datos_fiscales.razon_social != '' ? true : false;
          this.editar();
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    agregar: function() {
      this.model = this.clean_model();
      this.update = false;
      this.modal = true;
      this.factura = false;
      this.active_tab = "datos_generales";
    },
    editar: function() {
      this.update = true;
      this.modal = true;
      this.tmp_fecha = this.model.fecha_alta;
      this.model.fecha_alta = window.moment(this.model.fecha_alta).format("DD-MM-YYYY")
      this.active_tab = "datos_generales";
    },
    guardar: function() {
      if (this.$refs.form_general.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {

          let data = {
            type: this.model.type,
            nombre: this.model.nombre.toString().trim(),
            nombre_upper: this.model.nombre.toString().trim().toUpperCase(),
            cargo: (this.model.cargo != undefined && this.model.cargo != null) ? this.model.cargo.toString().trim() : "",
            rfc: this.model.rfc.toString().trim(),
            curp: this.model.curp.toString().trim(),
            telefono_casa: this.model.telefono_casa,
            telefono_oficina: this.model.telefono_oficina != null ? this.model.telefono_oficina.toString().trim() : "",
            fax: this.model.fax != null ? this.model.fax.toString().trim() : "",
            tipo_persona: this.model.tipo_persona,
            sexo: this.model.sexo,
            edad: this.model.edad,
            email: this.model.email.toString().trim(),
            reg_iesps: this.model.reg_iesps != null ? this.model.reg_iesps.toString().trim() : "",
            fecha_alta: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
            estatus: this.model.estatus,
            direcciones: this.model.direcciones.length > 0 ? this.model.direcciones : [],
            datos_fiscales: {
              razon_social: "",
              regimen_fiscal: "",
              domicilio: "",
              numero_exterior: "",
              numero_interior: "",
              colonia: "",
              ciudad: "",
              codigo_postal: "",
              representante: "",
              fecha_sociedad: "",
              notario: "",
              no_escritura: "",
              ciudad_representante: ""
            }
          };

          if (this.factura == true) {
            if (this.$refs.form_fiscales != undefined && this.$refs.form_fiscales.validate()) {
              data.datos_fiscales.razon_social = this.model.datos_fiscales.razon_social.toString().trim();
              data.datos_fiscales.regimen_fiscal = this.model.datos_fiscales.regimen_fiscal.toString().trim();
              data.datos_fiscales.domicilio = this.model.datos_fiscales.domicilio.toString().trim();
              data.datos_fiscales.numero_exterior = this.model.datos_fiscales.numero_exterior;
              data.datos_fiscales.numero_interior = this.model.datos_fiscales.numero_interior != null ? this.model.datos_fiscales.numero_interior.toString().trim() : "";
              data.datos_fiscales.colonia = this.model.datos_fiscales.colonia != null ? this.model.datos_fiscales.colonia.toString().trim() : "";
              data.datos_fiscales.ciudad = this.model.datos_fiscales.ciudad != null ? this.model.datos_fiscales.ciudad.toString().trim() : "";
              data.datos_fiscales.codigo_postal = this.model.datos_fiscales.codigo_postal != null ? this.model.datos_fiscales.codigo_postal.toString().trim() : "";

              if (this.model.tipo_persona.id == 2) {
                if (this.$refs.form_morales.validate()) {
                  data.datos_fiscales.representante = this.model.datos_fiscales.representante.toString().trim();
                  data.datos_fiscales.fecha_sociedad = this.model.datos_fiscales.fecha_sociedad.toString().trim();
                  data.datos_fiscales.notario = this.model.datos_fiscales.notario.toString().trim();
                  data.datos_fiscales.no_escritura = this.model.datos_fiscales.no_escritura.toString().trim();
                  data.datos_fiscales.ciudad_representante = this.model.datos_fiscales.ciudad_representante.toString().trim();
                } else {
                  window.dialogLoader.hide();
                  this.$swal({
                    type: "info",
                    title: "¡Validación!",
                    text: "Debe llenar correctamente los datos de Persona Moral.",
                    footer: ""
                  });
                  return;
                }
              }
            } else {
              window.dialogLoader.hide();
              this.$swal({
                type: "info",
                title: "¡Validación!",
                text: "Debe llenar correctamente los datos de Información Fiscal.",
                footer: ""
              });
              return;
            }
          }

          window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
              this.modal = false;
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });              
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        }).catch(err => {
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },
    actualizar: function() {
      if (this.$refs.form_general.validate()) {
        this.validaUnicos().then(result => {
          if (this.factura == true) {
            if (this.$refs.form_fiscales != undefined && !this.$refs.form_fiscales.validate()) {
              window.dialogLoader.hide();
              this.$swal({
                type: "info",
                title: "¡Validación!",
                text: "Debe llenar correctamente los datos de Información Fiscal.",
                footer: ""
              });
              return;
            }

            if (this.model.tipo_persona.id == 2) {
              if (!this.$refs.form_morales.validate()) {
                window.dialogLoader.hide();
                this.$swal({
                  type: "info",
                  title: "¡Validación!",
                  text: "Debe llenar correctamente los datos de Persona Moral.",
                  footer: ""
                });
                return;
              }
            }
          }
          let data = this.model;
          data["nombre_upper"] = this.model.nombre.toString().trim().toUpperCase();
          data.fecha_alta = this.tmp_fecha;
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });              
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });

        }).catch(err => {
          console.log(err);
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },

    eliminar: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });              
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(() => {
              window.dialogLoader.hide();
            });

        }

      });
    },

    validaUnicos: async function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
        await self.validaRFC().then(result => {}).catch(err => {
          duplicados.push("RFC");
        });

        if (duplicados.length > 0)
          return reject(duplicados);
        else
          return resolve(true);

      });

    },

    validaRFC: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.rfc != null && self.model.rfc != "") {

          if(self.model.rfc.toString().trim().toUpperCase() == "XAXX010101000"){ //Si es el generico si se permite registrar
            return resolve(true); 
          } else {
            var busca = encodeURI(escape(self.model.rfc.toString().trim().toLowerCase()));
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/clientes/_view/rfc?key=\"' + busca + '\"')
              .then(response => {

                if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                  if (self.model._id != "") { //Esta editando
                    var filter = response.data.rows.find(r => {
                      return self.model._id != r.id;
                    });

                    if (filter) {
                      return reject(false);
                    }
                  } else { //Es nuevo
                    return reject(false);
                  }
                }
                return resolve(true);
              })
              .catch(err => {
                return reject(false);
              });
          }
          
        } else
          return resolve(true);
      });
    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },

    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    agregar_direcciones: function() {
      this.model.direcciones.push({
        domicilio: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        ciudad: "",
        referencia: "",
        codigo_postal: "",
      });
    },
    quitar_direccion: function(index) {
      Vue.delete(this.model.direcciones, index);
    },
    get_fecha: function(fecha) {
      try {
        return window.moment(fecha).format("DD-MM-YYYY");
      } catch (e) {
        return "";
      }
    }, 
    verificaObligatorios: function(results, camposObligatorios) {
      var errores = '';
      var keys = [];
      for (var k in results) {
        keys.push(k);
      }

      camposObligatorios.forEach(function(campo) {
        if (keys.indexOf(campo) == -1)
          errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
      });

      return errores == '' ? true : errores;
    },
    validaCargaLayout: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var errorTmp = '';
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        try {
          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 10000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 10 000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatorios);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                if (!patternEmail.test(results[k]['email'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El email " + results[k]['email'] + " no es válido.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }
                
                if (results[k]['tipo_persona'] && !["Persona Moral", "Persona Fisica"].includes(results[k]['tipo_persona'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El Tipo: " + results[k]['tipo_persona'] + " no es un válido (Persona Moral|Persona Fisica).";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (results[k]['sexo'] && !["Masculino", "Femenino"].includes(results[k]['sexo'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El sexo " + results[k]['sexo'] + " no es un válido (Masculino|Femenino).";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }
                //validar categorias
                if (results[k]['regimen_fiscal'] != null && results[k]['regimen_fiscal'].toString().trim() != "") {
                  
                    var valCatego = self.validaRegimenFiscal(results[k]['regimen_fiscal'].toString().trim());
                    if (valCatego === "") {
                      errores++;
                      errorTmp = '<br/>' + "- El Regimen Fiscal " + results[k]['regimen_fiscal'] + " no se encontró en el catálogo.";
                      if (erroresText.indexOf(errorTmp) == -1)
                        erroresText += errorTmp;
                    } else
                      results[k]['regimen_fiscal'] = results[k]['regimen_fiscal'].toString().trim();                                    
                }

                if(["PUBLICO EN GENERAL","PÚBLICO EN GENERAL","PUBLICO GENERAL","PÚBLICO GENERAL"].includes(results[k]['nombre'].toString().trim().toUpperCase())){
                    errores++;
                    errorTmp = '<br/>' + "- El registro de " + results[k]['nombre'] + " no se debe editar o duplicar ya que afecta a la facturación.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                }                

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccess: async function(
      {
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      var self = this;     
      try {
        window.dialogLoader.show('Espere un momento por favor..');           
              self.getClientesAll().then(result => {
                self.validaCargaLayout(results).then(validaciones => {
                  window.dialogLoader.show('Espere un momento por favor..');

                  if (validaciones.errores > 0) {
                    this.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                      footer: validaciones.erroresText,
                    });
                    window.dialogLoader.hide();
                  } else {
                    for (var k in results) {
                      //Buscar rfc y nombre; si ya existe, traerse _id y _rev para editar
                      var rfc = results[k]['rfc'].toString().trim().toUpperCase();
                      var nombre = results[k]['nombre'].toString().trim().toUpperCase();
                      var key = rfc+"_"+nombre;
                      if (importData[key] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                        importData[key].type = "clientes";
                        importData[key].nombre = results[k]['nombre'].toString().trim().toUpperCase();
                        importData[key].nombre_upper = results[k]['nombre'].toString().trim().toUpperCase();
                        importData[key].cargo = results[k]['cargo']!=undefined ? results[k]['cargo'] : "";
                        importData[key].rfc = results[k]['rfc'].toString().trim().toUpperCase();
                        importData[key].curp = results[k]['curp']!=undefined ? results[k]['curp'] : "";
                        importData[key].telefono_casa = results[k]['telefono_casa']!=undefined ? results[k]['telefono_casa'] : "";
                        importData[key].telefono_oficina = results[k]['telefono_oficina']!=undefined ? results[k]['telefono_oficina'] : "";
                        importData[key].fax = results[k]['fax']!=undefined ? results[k]['fax'] : "";
                        if(results[k]['tipo_persona']=="Persona Moral")
                            importData[key].tipo_persona = {"id":2, "nombre": results[k]['tipo_persona']};
                          else 
                            importData[key].tipo_persona = {"id":1, "nombre": results[k]['tipo_persona']};
                        importData[key].sexo = results[k]['sexo']!=undefined ? results[k]['sexo'] : "";
                        importData[key].edad = results[k]['edad']!=undefined ? results[k]['edad'] : "";
                        importData[key].email = results[k]['email'];
                        importData[key].fecha_alta = window.moment().format("YYYY-MM-DDTHH:mm:ss")
                        importData[key].reg_iesps = results[k]['reg_iesps']!=undefined ? results[k]['reg_iesps'] : "";
                        if(results[k]['domicilio']!=undefined){
                          importData[key].direcciones = [
                            {
                              domicilio: results[k]['domicilio']!=undefined ? results[k]['domicilio'] : "",
                              numero_exterior: results[k]['numero_exterior']!=undefined ? results[k]['numero_exterior'] : "",
                              numero_interior: results[k]['numero_interior']!=undefined ? results[k]['numero_interior'] : "",
                              colonia: results[k]['colonia']!=undefined ? results[k]['colonia'] : "",
                              ciudad: results[k]['ciudad']!=undefined ? results[k]['ciudad'] : "",
                              codigo_postal: results[k]['codigo_postal']!=undefined ? results[k]['codigo_postal'] : "",  
                              referencias: results[k]['referencias']!=undefined ? results[k]['referencias'] : "",
                            }
                          ];
                        } else {
                          importData[key].direcciones = [];  
                        }
                        
                        if(results[k]['razon_social']!=undefined){
                          importData[key].datos_fiscales = {
                            razon_social: results[k]['razon_social']!=undefined ? results[k]['razon_social'] : "",
                            regimen_fiscal: results[k]['regimen_fiscal']!=undefined ? results[k]['regimen_fiscal'] : "",
                            domicilio: results[k]['domicilio_fiscal']!=undefined ? results[k]['domicilio_fiscal'] : "",
                            numero_exterior: results[k]['numero_exterior_fiscal']!=undefined ? results[k]['numero_exterior_fiscal'] : "",
                            numero_interior: results[k]['numero_interior_fiscal']!=undefined ? results[k]['numero_interior_fiscal'] : "",
                            colonia: results[k]['colonia_fiscal']!=undefined ? results[k]['colonia_fiscal'] : "",
                            ciudad: results[k]['ciudad_fiscal']!=undefined ? results[k]['ciudad_fiscal'] : "",
                            codigo_postal: results[k]['codigo_postal_fiscal']!=undefined ? results[k]['codigo_postal_fiscal'] : "",
                          };
                        } else {
                          importData[key].datos_fiscales = {};
                        }
                        importData[key].estatus = "Activo";
                      } else {
                        var clienteBuscado = self.validaClienteImport(rfc, nombre);
                        if (clienteBuscado != "") { //Ya existe, hay que actualizar
                          importData[key] = clienteBuscado;                                                    
                        } else {
                          importData[key] = {};  
                          importData[key].estatus = "Activo";                        
                        }
                          importData[key].type = "clientes";
                          importData[key].nombre = results[k]['nombre'].toString().trim().toUpperCase();
                          importData[key].nombre_upper = results[k]['nombre'].toString().trim().toUpperCase();
                          importData[key].cargo = results[k]['nombre']!=undefined ? results[k]['nombre'] : "";
                          importData[key].rfc = results[k]['rfc'].toString().trim().toUpperCase();
                          importData[key].curp = results[k]['curp']!=undefined ? results[k]['curp'] : "";
                          importData[key].telefono_casa = results[k]['telefono_casa']!=undefined ? results[k]['telefono_casa'] : "";
                          importData[key].telefono_oficina = results[k]['telefono_oficina']!=undefined ? results[k]['telefono_oficina'] : "";
                          importData[key].fax = results[k]['fax']!=undefined ? results[k]['fax'] : "";
                          if(results[k]['tipo_persona']=="Persona Moral")
                            importData[key].tipo_persona = {"id":2, "nombre": results[k]['tipo_persona']};
                          else 
                            importData[key].tipo_persona = {"id":1, "nombre": results[k]['tipo_persona']};

                          importData[key].sexo = results[k]['sexo']!=undefined ? results[k]['sexo'] : "";
                          importData[key].edad = results[k]['edad']!=undefined ? results[k]['edad'] : "";
                          importData[key].email = results[k]['email'];
                          importData[key].fecha_alta = window.moment().format("YYYY-MM-DDTHH:mm:ss")
                          importData[key].reg_iesps = results[k]['reg_iesps']!=undefined ? results[k]['reg_iesps'] : "";
                          if(results[k]['domicilio']!=undefined){
                            importData[key].direcciones = [
                              {
                                domicilio: results[k]['domicilio']!=undefined ? results[k]['domicilio'] : "",
                                numero_exterior: results[k]['numero_exterior']!=undefined ? results[k]['numero_exterior'] : "",
                                numero_interior: results[k]['numero_interior']!=undefined ? results[k]['numero_interior'] : "",
                                colonia: results[k]['colonia']!=undefined ? results[k]['colonia'] : "",
                                ciudad: results[k]['ciudad']!=undefined ? results[k]['ciudad'] : "",
                                codigo_postal: results[k]['codigo_postal']!=undefined ? results[k]['codigo_postal'] : "",  
                                referencias: results[k]['referencias']!=undefined ? results[k]['referencias'] : "",
                              }
                            ];
                          } else {
                            importData[key].direcciones = [];  
                          }
                          
                          if(results[k]['razon_social']!=undefined){
                            importData[key].datos_fiscales = {
                              razon_social: results[k]['razon_social']!=undefined ? results[k]['razon_social'] : "",
                              regimen_fiscal: results[k]['regimen_fiscal']!=undefined ? results[k]['regimen_fiscal'] : "",
                              domicilio: results[k]['domicilio_fiscal']!=undefined ? results[k]['domicilio_fiscal'] : "",
                              numero_exterior: results[k]['numero_exterior_fiscal']!=undefined ? results[k]['numero_exterior_fiscal'] : "",
                              numero_interior: results[k]['numero_interior_fiscal']!=undefined ? results[k]['numero_interior_fiscal'] : "",
                              colonia: results[k]['colonia_fiscal']!=undefined ? results[k]['colonia_fiscal'] : "",
                              ciudad: results[k]['ciudad_fiscal']!=undefined ? results[k]['ciudad_fiscal'] : "",
                              codigo_postal: results[k]['codigo_postal_fiscal']!=undefined ? results[k]['codigo_postal_fiscal'] : "",
                            };
                          } else {
                            importData[key].datos_fiscales = {};
                          }                          
                      }

                    }

                    var bulkDatos = [];
                    for (var key in importData) {
                      bulkDatos.push(importData[key]);
                    }

                    var docs = {
                      "docs": bulkDatos
                    };
                    window.axios
                      .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                      .then(response => {
                        self.$swal({
                          type: "success",
                          title: "¡Operación Exitosa!",
                          text: "Los datos se guardaron correctamente.",
                          footer: "",
                        });
                        self.modalImportar = false;
                        window.dialogLoader.hide();
                        self.items = []; 
                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');                                                

                      })
                      .catch(error => {
                        self.$swal({
                          type: "warning",
                          title: "¡Operación no Permitida!",
                          text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                          footer: "",
                        });
                        console.log(error);
                        window.dialogLoader.hide();                        
                      });

                  }
                }).catch(error => {
                  self.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo",
                    footer: error,
                  });
                  console.log(error);
                  window.dialogLoader.hide();

                });
              }).catch(error => {
                window.dialogLoader.hide();
                self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener los clientes.",
                  footer: ""
                });
              });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }
    },
  }
}
</script>
